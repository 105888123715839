var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "movie-prop__item",
      attrs: { title: "Click to delete" },
      on: {
        click: function($event) {
          return _vm.remove(_vm.index)
        }
      }
    },
    [
      _c("h2", { staticClass: "movie-prop__item-title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("p", { staticClass: "movie-prop__item-year" }, [
        _vm._v(_vm._s(_vm.year))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }