var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "method-prop" }, [
    _c("h1", { staticClass: "heading" }, [_vm._v("Method as a prop")]),
    _c("p", { staticClass: "paragraph" }, [
      _vm._v("Passing a parent method to a component")
    ]),
    _c("p", { staticClass: "paragraph" }, [
      _vm._v("Click an item to delete them.")
    ]),
    _c(
      "form",
      {
        staticClass: "method-prop__form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("fieldset", { staticClass: "method-prop__fieldset" }, [
          _c("legend", { staticClass: "method-prop__legend" }, [
            _vm._v("Create movies")
          ]),
          _c(
            "label",
            {
              staticClass: "method-prop__label",
              attrs: { for: "movie-title" }
            },
            [_vm._v(" Movie title: ")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.movieTitle,
                expression: "movieTitle"
              }
            ],
            ref: "title",
            staticClass: "method-prop__input",
            attrs: {
              type: "text",
              id: "movie-title",
              name: "title",
              placeholder: "Movie title"
            },
            domProps: { value: _vm.movieTitle },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.movieTitle = $event.target.value
              }
            }
          }),
          _c(
            "label",
            { staticClass: "method-prop__label", attrs: { for: "movie-year" } },
            [_vm._v(" Movie year: ")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.movieYear,
                expression: "movieYear"
              }
            ],
            ref: "year",
            staticClass: "method-prop__input",
            attrs: {
              type: "text",
              id: "movie-year",
              name: "year",
              placeholder: "Movie year"
            },
            domProps: { value: _vm.movieYear },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.movieYear = $event.target.value
              }
            }
          }),
          _c("input", {
            staticClass: "movie-prop__submit button button--02",
            attrs: { type: "submit", value: "Add movie" }
          })
        ])
      ]
    ),
    _vm.movies.length == 0
      ? _c("section", [
          _c("p", { staticClass: "movie-prop__no-movies" }, [
            _vm._v("Please add some movies above!")
          ])
        ])
      : _c(
          "section",
          { staticClass: "movie-prop__grid" },
          _vm._l(_vm.movies, function(movie, index) {
            return _c("MovieBlock", {
              key: index,
              attrs: {
                index: index,
                title: movie.title,
                year: movie.year,
                remove: _vm.removeItem
              }
            })
          }),
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }